import React from "react";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import H3 from "../components/typography/H3";
import P2 from "../components/typography/P2";
import "../main.css";
import whiteMonkey from "../images/other/tenor.gif";

const NotFoundPage = props => {
  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="Not Found" canonical={props.location.href} />
        <SiteHeader dark />
        <LayoutSection small center>
          <img
            src={whiteMonkey}
            style={{ margin: "0 auto 2em", position: "relative", width: "20em" }}
          />
          <H3>Not Found</H3>
          <P2>We're very confused as well, sorry.</P2>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default NotFoundPage;
